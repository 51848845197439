@tailwind base;
@tailwind components;
.form-control {
 @apply  w-full leading-snug px-3 py-2  rounded bg-zinc-700 text-white border border-zinc-600  ;

}
.form-control:hover {
  @apply  bg-white text-black;
 
 }
 .form-control:focus {
  @apply  outline-none;
 
 }
 .btn {
  @apply  px-4 py-2 m-3 shadow-xl  block  rounded ;
 }
 .btn:hover {
  @apply  shadow-none;
 }
 .btn-primary {
  @apply bg-blue-700 text-white border border-blue-500;
 }
 .btn-primary:hover{
  @apply  bg-blue-500   text-blue-900 font-bold border border-blue-700 ;
 }
 .btn-primary:focus{
  @apply  outline-none;
 }
 .btn-primary:disabled{
  @apply  bg-blue-400;
 }
 .btn-danger {
  @apply bg-red-700 text-white border border-red-500;
 }
 .btn-danger:hover{
  @apply  bg-red-500   text-red-900 font-bold border border-red-700 ;
 }
 .btn-danger:focus{
  @apply  outline-none;
 }
 .btn-danger:disabled{
  @apply  bg-red-400;
 }
@tailwind utilities;

@layer base {
    html {
      font-family: Quicksand, system-ui, sans-serif;
    }
  }
@layer  {
  .rotate-45 {
    transform: rotate(45deg);
  }
}



  @layer hover {
    .zoom {
      transform: scale(2);
    }
  }
  @layer components {
    .alert {
      @apply py-2 px-4 font-semibold rounded-lg shadow-md hover:bg-gray-900  p-5 m-6;
    }
    .alert-default {
      @apply  bg-gray-500 text-gray-900 hover:bg-gray-500 hover:text-gray-200 border border-gray-900;
    }
    .alert-danger {
      @apply bg-red-200   text-red-600 hover:bg-red-500 hover:text-red-200 border border-red-900;
    }
    .alert-warning {
      @apply  bg-yellow-200   text-yellow-600 hover:bg-yellow-600 hover:text-yellow-200 border border-yellow-800;
    }
    .alert-info{
      @apply  bg-blue-200   text-blue-900 hover:bg-blue-500 hover:text-blue-200 border border-blue-800;
    }
  }
  h1 {

    @apply text-2xl text-blue-500 font-semibold;
  }
  h2 {

    @apply text-2xl text-green-700 font-semibold;
  }
  h3 {

    @apply text-3xl text-blue-500 font-semibold;
  }
  h4 {

    @apply text-2xl  font-semibold;
  }
  h5 {

    @apply  text-yellow-200 font-semibold;
  }
  p {

    @apply  text-yellow-200 font-semibold;
  }