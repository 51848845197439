.App {
 
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    height: 100vh;
  }
  ::-webkit-scrollbar {
    width: 12px; /* for vertical scrollbars */
    height: 12px; /* for horizontal scrollbars */
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
  }
  .left-container {
    display: flex;
    max-height: 400px;
    max-width: 650px;
  }
  .left-container > div {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.15) 0px 8px 16px;
  }
  .css-1munbi2 {
    height: 80%;
  }
  .slider-container {
    padding: 6px;
    border-radius: 1rem;
    background-color: black;
    width: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 1rem;
    max-height: 180px;
  }
  .icon-container {
    padding: 6px;
   
  }
  .icon-undo {

  padding-left: 20px;
    color:white;
  }
